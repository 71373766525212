import { BlogHeader } from '@animoto/components';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import AnimotoLayoutSentryWrapped from './AnimotoLayout';

function searchRedirect(query) {
  navigate(encodeURI(`/blog/search/?query=${query}`));
}

function generateNavigation({ menuItems }) {
  return menuItems.reduce((value, currentValue) => {
    const { name, fields } = currentValue;
    const url = `/blog/category/${fields.categorySlug}`;
    value[name] = url;

    return value;
  }, {});
}

const blogMenuQuery = graphql`
  {
    contentfulBlogSettings {
      menuItems {
        name
        fields {
          categorySlug
        }
      }
    }
  }
`;

export default function AnimotoBlogLayout({ SEOInfo, children }) {
  const { contentfulBlogSettings } = useStaticQuery(blogMenuQuery);
  const seoInfo = {
    ...SEOInfo,
    context : 'blog'
  };

  return (
    <AnimotoLayoutSentryWrapped SEOInfo={seoInfo}>
      <BlogHeader
        indexPath="/blog"
        navigation={generateNavigation(contentfulBlogSettings)}
        onSearch={searchRedirect}
      />
      {children}
    </AnimotoLayoutSentryWrapped>
  );
}

AnimotoBlogLayout.propTypes = {
  children : PropTypes.node.isRequired,
  SEOInfo  : PropTypes.object // eslint-disable-line react/forbid-prop-types
};

AnimotoBlogLayout.defaultProps = {
  SEOInfo : {}
};
