import {
  ArticlePreview,
  CuratedArticlesList,
  InfiniteArticlesList
} from '@animoto/components';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import AnimotoBlogLayout from '../../components/AnimotoBlogLayout';
import BlogCommunityModule from '../../components/CommunityModule/BlogCommunityModule';
import {
  community,
  featuredPosts,
  recentPosts as recentPostsClass,
  recentPostsList } from './home.module.css';

export default function IndexPage({ data }) {
  const {
    allContentfulBlogPost: recentPosts,
    contentfulBlogSettings
  } = data;

  const featuredArticles = contentfulBlogSettings.featuredPosts.map(({
    body,
    description,
    featuredImage,
    fields,
    id,
    mainCategory,
    publishedOn,
    title,
    updatedAt
  }) => (
    <ArticlePreview
      key={id}
      category={(mainCategory && mainCategory.name) || ''}
      categoryUrl={(mainCategory && `/blog/category/${mainCategory.fields.categorySlug}`) || ''}
      imageSrcFluid={featuredImage && featuredImage.fluid}
      postUrl={`/blog/${fields.postSlug}`}
      publishDate={publishedOn || updatedAt}
      title={title}
    >
      {(description && description.childMarkdownRemark.excerpt) || (body && body.childMarkdownRemark.excerpt)}
    </ArticlePreview>
  ));

  const onShowMore = () => {
    window.location = '/blog/page/2';
  };

  const recentArticles = recentPosts.edges.map(({ node : {
    body,
    description,
    featuredImage,
    fields,
    id,
    mainCategory,
    publishedOn,
    title,
    updatedAt
  } }) => (
    <ArticlePreview
      key={id}
      category={(mainCategory && mainCategory.name) || ''}
      categoryUrl={(mainCategory && `/blog/category/${mainCategory.fields.categorySlug}`) || ''}
      imageSrcFluid={featuredImage && featuredImage.fluid}
      postUrl={`/blog/${fields.postSlug}`}
      publishDate={publishedOn || updatedAt}
      title={title}
    >
      {(description && description.childMarkdownRemark.excerpt) || (body && body.childMarkdownRemark.excerpt)}
    </ArticlePreview>
  ));

  return (
    <AnimotoBlogLayout>
      <CuratedArticlesList
        className={featuredPosts}
        title="Featured Posts"
      >
        {featuredArticles}
      </CuratedArticlesList>
      <div className={recentPostsClass}>
        <InfiniteArticlesList
          className={recentPostsList}
          hideShowMore={!recentPosts.pageInfo.hasNextPage}
          onShowMoreClick={onShowMore}
          title="Recent Posts"
        >
          {recentArticles}
        </InfiniteArticlesList>
      </div>
      <BlogCommunityModule className={community} />
    </AnimotoBlogLayout>
  );
}

export const query = graphql`
query HomepageInformation {
  contentfulBlogSettings {
    featuredPosts {
      body {
        childMarkdownRemark {
          excerpt
        }
      }
      description {
        childMarkdownRemark {
          excerpt
        }
      }
      featuredImage {
        fluid(maxWidth: 670, maxHeight: 447) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      fields {
        postSlug
      }
      id
      mainCategory {
        name
        fields {
          categorySlug
        }
      }
      publishedOn
      title
      updatedAt
    }
  }
  allContentfulBlogPost(
    filter: {
      noIndex: { eq: false },
      noIndexOnBlog: { ne: true },
      fields: { featuredPost: { eq: false } }
    },
    limit: 6,
    sort: {fields : publishedOn, order :DESC})
  {
    pageInfo {
      hasNextPage
    }
    edges {
      node {
        body {
          childMarkdownRemark {
            excerpt
          }
        }
        description {
          childMarkdownRemark {
            excerpt
          }
        }
        featuredImage {
          fluid(maxWidth: 670, maxHeight: 447) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        fields {
          postSlug
        }
        id
        mainCategory {
          name
          fields {
            categorySlug
          }
        }
        publishedOn
        title
        updatedAt
      }
    }
  }
}
`;
IndexPage.propTypes = {
  data : PropTypes.shape({
    allContentfulBlogPost : PropTypes.shape({
      edges : PropTypes.arrayOf(PropTypes.shape({
        node : PropTypes.shape({
          body : PropTypes.shape({
            childMarkdownRemark : PropTypes.shape({
              excerpt : PropTypes.string
            })
          }),
          description : PropTypes.shape({
            childMarkdownRemark : PropTypes.shape({
              excerpt : PropTypes.string
            })
          }),
          featuredImage : PropTypes.shape({
            fixed : PropTypes.shape({
              src : PropTypes.string
            })
          }),
          fields : PropTypes.shape({
            postSlug : PropTypes.string
          }),
          id           : PropTypes.string,
          mainCategory : PropTypes.shape({
            fields : PropTypes.shape({
              categorySlug : PropTypes.string
            }),
            name : PropTypes.string
          }),
          publishedOn         : PropTypes.string,
          retinaFeaturedImage : PropTypes.shape({
            fixed : PropTypes.shape({
              src : PropTypes.string
            })
          }),
          title     : PropTypes.string,
          updatedAt : PropTypes.string
        })
      })),
      pageInfo : PropTypes.shape({
        hasNextPage : PropTypes.bool
      })
    }),
    contentfulBlogSettings : PropTypes.shape({
      featuredPosts : PropTypes.arrayOf(PropTypes.shape({
        body : PropTypes.shape({
          childMarkdownRemark : PropTypes.shape({
            excerpt : PropTypes.string
          })
        }),
        description : PropTypes.shape({
          childMarkdownRemark : PropTypes.shape({
            excerpt : PropTypes.string
          })
        }),
        featuredImage : PropTypes.shape({
          fixed : PropTypes.shape({
            src : PropTypes.string
          })
        }),
        fields : PropTypes.shape({
          postSlug : PropTypes.string
        }),
        id           : PropTypes.string,
        mainCategory : PropTypes.shape({
          fields : PropTypes.shape({
            categorySlug : PropTypes.string
          }),
          name : PropTypes.string
        }),
        publishedOn         : PropTypes.string,
        retinaFeaturedImage : PropTypes.shape({
          fixed : PropTypes.shape({
            src : PropTypes.string
          })
        }),
        title     : PropTypes.string,
        updatedAt : PropTypes.string
      }))
    })
  }).isRequired
};
