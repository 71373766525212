// extracted by mini-css-extract-plugin
export var base = "home-module--base--2524O";
export var rightAlignedContainer = "home-module--rightAlignedContainer--2CiEn";
export var rightAlignedContainerSmall = "home-module--rightAlignedContainerSmall--3QeBK";
export var leftAlignedContainer = "home-module--leftAlignedContainer--MDA_T";
export var blogContainer = "home-module--blogContainer--2COVy home-module--base--2524O";
export var container = "home-module--container--38zlf home-module--base--2524O";
export var largeContainer = "home-module--largeContainer--2BQsa home-module--base--2524O";
export var mobileSmallPadding = "home-module--mobileSmallPadding--24GUG";
export var fullWidthMobile = "home-module--fullWidthMobile--3owGb";
export var gridWithSidebar = "home-module--gridWithSidebar--31X1P";
export var noMaxWidth = "home-module--noMaxWidth--1KiiU";
export var featuredPosts = "home-module--featuredPosts--3eu13 home-module--blogContainer--2COVy home-module--base--2524O";
export var recentPosts = "home-module--recentPosts--2kQUR";
export var recentPostsList = "home-module--recentPostsList--1rzKG home-module--blogContainer--2COVy home-module--base--2524O";
export var community = "home-module--community--3HPGM";