import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import CommunityModule from '..';

const blogCommunityModuleQuery = graphql`
  query {
    contentfulComponentCommunity(isBlogEntry: {eq: true}) {
      ...CommunityModuleData
    }
  }
`;

export default function BlogCommunityModule({ className }) {
  const { contentfulComponentCommunity } = useStaticQuery(blogCommunityModuleQuery);
  return (
    <CommunityModule className={className} data={contentfulComponentCommunity} />
  );
}

BlogCommunityModule.propTypes = {
  className : PropTypes.string
};

BlogCommunityModule.defaultProps = {
  className : null
};
